import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/tags?${params}`);
  },

  create(payload) {
    return axios.post(`marketplace_crm/tags`, payload);
  },

  update(id, payload) {
    return axios.patch(`marketplace_crm/tags/${id}`, payload);
  },

  delete(id) {
    return axios.delete(`marketplace_crm/tags/${id}`);
  },

  tag(id) {
    return axios.get(`marketplace_crm/tags/find/${id}`);
  },

  toggleStatus(id) {
    return axios.patch(`marketplace_crm/tags/${id}/toggle`);
  },

  products(id, params) {
    return axios.get(`marketplace_crm/tags/${id}/products`, {
      params: params,
    });
  },
};
