<template>
  <div>
    <eden-loader v-if="loading" />
    <div v-else>
      <eden-table-actions
        placeholder="Search for a product"
        :title="title"
        @search="search"
        show-search
        v-if="tagProducts.length || searchQuery"
      >
      </eden-table-actions>
      <div class="eden-table-scroll">
        <el-table
          table-layout="auto"
          :data="tagProducts"
          v-if="tagProducts.length"
        >
          <el-table-column type="selection" width="45" />
          <el-table-column width="400">
            <template #header>
              <div class="table--header">
                <span>Name</span>
                <i class="el-icon-top"></i>
              </div>
            </template>
            <template v-slot="scope">
              <div class="is-flex is-align-center">
                <div class="eden-meal--image">
                  <img
                    v-if="scope.row.image_url || scope.row.image_url === ''"
                    :src="getThumbnailFromCloudinary(scope.row.image_url)"
                    class="meal--image"
                    alt="Image"
                    width="40px"
                    height="40px"
                  />
                </div>
                {{ scope.row.product.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <div class="table--header">
                <span>Price</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              {{ formatPrice(scope.row.product.price) }}
            </template>
          </el-table-column>

          <el-table-column>
            <template #header>
              <div class="table--header">
                <span>Name of Vendor</span>
                <i class="el-icon-bottom"></i>
              </div>
            </template>
            <template v-slot="scope">
              {{ scope.row.vendor.name }}
            </template>
          </el-table-column>
        </el-table>
        <eden-content-empty
          v-if="!tagProducts.length && searchQuery"
          :text="'No Products'"
        />
      </div>

      <div class="empty-content" v-if="!tagProducts.length && !searchQuery">
        <p class="text-center" style="line-height: 24px">
          Currently, there are no products associated with this Tag. <br />
          As vendors add tags to their products, you'll find them listed here.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import tag from "@/requests/tags/tag";
export default {
  name: "TagProductsTable",
  data() {
    return {
      loading: false,
      searchQuery: "",
      tagProducts: [],
    };
  },
  computed: {
    title() {
      return `${this.tagProducts.length} Products`;
    },
    tagId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    search(query) {
      this.searchQuery = query;
      this.getProducts();
    },
    getProducts() {
      this.loading = true;
      tag
        .products(this.tagId, {
          query: this.searchQuery,
        })
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.tagProducts = response.data.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style>
.empty-content {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 125px;
}

.el-button--primary {
  margin-top: 15px !important;
}

.meal--image {
  margin-right: 12px;
  border-radius: 8px;
}
</style>
