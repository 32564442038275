<template>
  <div>
    <div v-if="loading">loading</div>
    <template v-else>
      <eden-page-header :title="'Tags'" :subtitle="tagName"> </eden-page-header>
      <eden-table-actions :title="'Information'" :show-search="false">
        <template #actions>
          <div class="mr-2">
            {{ status ? "Tag Active " : "Tag Inactive " }}
            <el-switch
              v-model="status"
              :active-value="true"
              :inactive-value="false"
              size="large"
              @change="handleToggle"
            />
          </div>
          <div>
            <el-dropdown
              v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
              :placement="'bottom'"
              @command="command"
            >
              <el-button type="plain">
                Actions
                <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{ action: 'edit' }"
                  icon="eden-icon--edit"
                  >Edit Tag</el-dropdown-item
                >
                <el-dropdown-item
                  class="text-danger"
                  :command="{ action: 'delete' }"
                  icon="eden-icon--eye"
                  >Delete Tag</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </eden-table-actions>
      <div class="is-flex tag-table gap-4 align-end mb-4">
        <div
          v-if="filterTags.image_url"
          :style="{
            backgroundImage: `url(${filterTags.image_url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '216px',
            width: '357px',
            borderRadius: '8px',
          }"
        ></div>
        <eden-information-card class="w-100">
          <template slot="content">
            <el-table :class="'general'" :data="info" :show-header="false">
              <el-table-column>
                <template slot-scope="scope">
                  <template>
                    <el-table
                      :class="'inner-table'"
                      :data="scope.row.data"
                      :show-header="false"
                    >
                      <el-table-column class="none">
                        <template slot-scope="scope">
                          <span class="label font-xsm">{{
                            scope.row.label
                          }}</span>
                          <p class="text font-sm">
                            {{ scope.row.text }}
                          </p>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </template>
              </el-table-column></el-table
            >
          </template>
        </eden-information-card>
      </div>

      <TagProductsTable />
    </template>

    <TagsForm
      :action="tag.action"
      :show.sync="showEditModal"
      @success="getTag"
      :tag="tag.data"
    />
    <tag-delete
      :show.sync="showDeleteTag"
      :tag-id="TagId"
      @success="$router.go(-1)"
    />
  </div>
</template>

<script>
import TagsForm from "@/components/Tags/TagsForm.vue";
import TagDelete from "@/components/Tags/TagDelete.vue";
import TagProductsTable from "@/components/Tags/TagProductsTable.vue";
import tag from "@/requests/tags/tag";
export default {
  name: "TagDetails",
  components: {
    TagsForm,
    TagDelete,
    TagProductsTable,
  },
  data() {
    return {
      filterProducts: [],
      filterTags: {},
      showDeleteTag: false,
      showEditModal: false,
      tag: {
        action: "add",
        visibility: false,
        data: {},
      },
      loading: false,
      status: false,
    };
  },
  computed: {
    TagId() {
      return this.$route.params.id;
    },
    tagName() {
      return this.filterTags.name;
    },
    info() {
      return [
        {
          data: [
            {
              label: "Name",
              text: this.tagName ?? "-",
            },
          ],
        },
        {
          data: [
            {
              label: "Business Category",
              text: this.filterTags?.business_type?.name ?? "-",
            },
          ],
        },
      ];
    },
  },
  created() {
    this.getTag();
  },
  methods: {
    command(command) {
      switch (command.action) {
        case "edit":
          this.tag.data = this.filterTags;
          this.tag.action = "edit";
          this.showEditModal = true;
          break;
        case "delete":
          this.tag.data = this.filterTags;
          this.showDeleteTag = true;
          break;
      }
    },
    handleToggle() {
      tag
        .toggleStatus(this.TagId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.$router.go();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    getTag() {
      this.loading = true;
      tag
        .tag(this.TagId)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.filterTags = response.data.data;
            this.status = response.data.data.is_active;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
tr.el-table__row:last-child {
  border-left: 1px solid #ebeef4 !important;
}

.information-card,
td.el-table_4_column_4.el-table__cell {
  padding: 0 !important;
}

td.el-table_4_column_4.el-table__cell {
  border-left: none !important;
}

.el-table__cell {
  padding: 0 !important;
}

.tag-table {
  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
  }
}
</style>
