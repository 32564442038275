<template>
  <el-dialog
    :title="`${formatText(action)} Tag`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form :model="form" label-position="top">
      <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
        <el-col :md="24" class="">
          <eden-image-drag-and-drop
            :image-url.sync="form.image_url"
            :image-text="'banner logo'"
            :image-folder="'tags'"
          />
          <p class="text-center">
            Use image in JPG or PNG format, under 5MB in size, and have a
            minimum resolution of 375px x 200px.
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Tag Name" prop="name">
            <el-input
              class=""
              v-model="form.name"
              type="text"
              placeholder="Tag Name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-form-item label="Business Type" prop="business_type_id">
            <el-select
              v-model="form.business_type_id"
              :value-key="'id'"
              :loading="loading"
            >
              <el-option
                v-for="item in businessType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="action === 'create'"
        type="primary"
        :loading="loading"
        @click="createTag"
        >Create Tag</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="update"
        >Save Tag</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import tag from "@/requests/tags/tag";
import * as actions from "@/store/action-types";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "create",
    },
    tag: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: "",
        business_type_id: null,
        image_url: "",
      },
      businessType: [],
      loading: false,
      updating: false,
    };
  },

  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.form = {
            name: this.tag.name,
            business_type_id: this.tag.business_type.id,
            image_url: this.tag.image_url,
          };
        }
      }
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    businessTypes() {
      return this.$store.getters.business_types;
    },
  },
  created() {
    this.getBusinessType();
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        name: "",
        business_type_ids: null,
        image_url: "",
      };
    },
    getBusinessType() {
      this.loading = true;
      this.$store.dispatch(actions.GET_BUSINESS_TYPE).then(() => {
        this.businessType = this.businessTypes;
        this.loading = false;
      });
    },

    createTag() {
      this.loading = true;
      tag
        .create(this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.updating = true;
      tag
        .update(this.tag.uuid, this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>
