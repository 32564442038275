<template>
  <eden-confirm-dialog
    title="Delete Tag?"
    button-type="danger"
    button-text="Delete Tag"
    :show.sync="shouldShow"
    @confirm="deleteTag"
  >
    <p>
      If you delete this tag, all data associated with it will be permanently
      erased, This action cannot be undone.
    </p>
  </eden-confirm-dialog>
</template>

<script>
import tag from "@/requests/tags/tag";

export default {
  name: "TagDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tagId: String,
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.deleting = false;
    },
    deleteTag() {
      tag
        .delete(this.tagId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.tagId);
            if (response.data.message === "Tag not found") {
              this.$router.push({ name: "tags.index" });
            }
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.shouldShow = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
