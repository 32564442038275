var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_vm._v("loading")]):[_c('eden-page-header',{attrs:{"title":'Tags',"subtitle":_vm.tagName}}),_c('eden-table-actions',{attrs:{"title":'Information',"show-search":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.status ? "Tag Active " : "Tag Inactive ")+" "),_c('el-switch',{attrs:{"active-value":true,"inactive-value":false,"size":"large"},on:{"change":_vm.handleToggle},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',[(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('el-dropdown',{attrs:{"placement":'bottom'},on:{"command":_vm.command}},[_c('el-button',{attrs:{"type":"plain"}},[_vm._v(" Actions "),_c('i',{staticClass:"eden-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{ action: 'edit' },"icon":"eden-icon--edit"}},[_vm._v("Edit Tag")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{ action: 'delete' },"icon":"eden-icon--eye"}},[_vm._v("Delete Tag")])],1)],1):_vm._e()],1)]},proxy:true}])}),_c('div',{staticClass:"is-flex tag-table gap-4 align-end mb-4"},[(_vm.filterTags.image_url)?_c('div',{style:({
          backgroundImage: `url(${_vm.filterTags.image_url})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '216px',
          width: '357px',
          borderRadius: '8px',
        })}):_vm._e(),_c('eden-information-card',{staticClass:"w-100"},[_c('template',{slot:"content"},[_c('el-table',{class:'general',attrs:{"data":_vm.info,"show-header":false}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[_c('el-table',{class:'inner-table',attrs:{"data":scope.row.data,"show-header":false}},[_c('el-table-column',{staticClass:"none",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"label font-xsm"},[_vm._v(_vm._s(scope.row.label))]),_c('p',{staticClass:"text font-sm"},[_vm._v(" "+_vm._s(scope.row.text)+" ")])]}}],null,true)})],1)]]}}])})],1)],1)],2)],1),_c('TagProductsTable')],_c('TagsForm',{attrs:{"action":_vm.tag.action,"show":_vm.showEditModal,"tag":_vm.tag.data},on:{"update:show":function($event){_vm.showEditModal=$event},"success":_vm.getTag}}),_c('tag-delete',{attrs:{"show":_vm.showDeleteTag,"tag-id":_vm.TagId},on:{"update:show":function($event){_vm.showDeleteTag=$event},"success":function($event){return _vm.$router.go(-1)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }